<template>
	<div style="padding: 20px;">
		<el-form ref="form" :model="form" label-width="200px">
			<el-col :span="12">
				<el-form-item :label="$t('custom.page2.换热量')">
					<el-select v-model="form.heatLoad" placeholder="请选择">
						<el-option v-for="item in pickerRanges.unit_heat_load" :key="item.title" :label="item.title"
							:value="item.title">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>

			<el-col :span="12">
				<el-form-item :label="$t('newadd.t.温度')">
					<el-select v-model="form.temperature" placeholder="请选择">
						<el-option v-for="item in pickerRanges.unit_temperature" :key="item.title" :label="item.title"
							:value="item.title">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>

			<el-col :span="12">
				<el-form-item  :label="$t('newadd.t.压力')" >
					<el-select v-model="form.pressure" placeholder="请选择">
						<el-option v-for="item in pickerRanges.unit_pressure" :key="item.title" :label="item.title"
							:value="item.title">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item  :label="$t('custom.page0.风压')" >
					<el-select v-model="form.airPressureDrop" placeholder="请选择">
						<el-option v-for="item in pickerRanges.unit_pressure" :key="item.title" :label="item.title"
							:value="item.title">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item  :label="$t('custom.page2.冷媒降压')">
					<el-select v-model="form.refPressureDrop" placeholder="请选择">
						<el-option v-for="item in pickerRanges.unit_pressure" :key="item.title" :label="item.title"
							:value="item.title">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item  :label="$t('newadd.t.体积流量')">
					<el-select v-model="form.volumeFlow" placeholder="请选择">
						<el-option v-for="item in pickerRanges.unit_volume_flow" :key="item.title" :label="item.title"
							:value="item.title">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item  :label="$t('newadd.t.质量流量')">
					<el-select v-model="form.massFlow" placeholder="请选择">
						<el-option v-for="item in pickerRanges.unit_mass_flow" :key="item.title" :label="item.title"
							:value="item.title">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item :label="$t('newadd.t.速度')">
					<el-select v-model="form.velocity" placeholder="请选择">
						<el-option v-for="item in pickerRanges.unit_velocity" :key="item.title" :label="item.title"
							:value="item.title">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>
			<!-- <el-col :span="12">
				<el-form-item  :label="$t('newadd.t.长度')">
					<el-select v-model="form.length" placeholder="请选择">
						<el-option v-for="item in pickerRanges.unit_length" :key="item.title" :label="item.title"
							:value="item.title">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col> -->
			<!-- <el-col :span="12">
				<el-form-item :label="$t('newadd.t.质量')">
					<el-select v-model="form.mass" placeholder="请选择">
						<el-option v-for="item in pickerRanges.unit_weight" :key="item.title" :label="item.title"
							:value="item.title">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col> -->
			<el-col :span="12">
				<el-form-item label="" :label="$t('newadd.t.充注量')">
					<el-select v-model="form.charge" placeholder="请选择">
						<el-option v-for="item in pickerRanges.unit_weight" :key="item.title" :label="item.title"
							:value="item.title">
						</el-option>
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="24">
				
					<el-button type="primary" @click="onSubmit" size="medium" class="submit">{{ $t('login.edittt') }}</el-button>
				
			</el-col>


		</el-form>
	</div>
</template>

<script>
	import {
		getDictListBatch
	} from "@/api/common";
	import {updateUserUnit} from "@/api/User";
	export default {
		data() {
			return {
				form: {
					// 换热量
					heatLoad: "",
					// 温度
					temperature: "",
					// 压力
					pressure: "",
					// 风压
					airPressureDrop: "",
					// 冷媒降压
					refPressureDrop: "",
					// 体积流量
					volumeFlow: "",
					// 质量流量
					massFlow: "",
					// 速度
					velocity: "",
					// 长度
					length: "",
					// 质量
					mass: "",
					// 充注量
					charge: "",
				},
				pickerRanges: {
					//长度单位
					"unit_length": [],
					//速度单位
					"unit_velocity": [],
					//质量流量单位
					"unit_mass_flow": [],
					//体积流量单位
					"unit_volume_flow": [],
					//重量单位
					"unit_weight": [],
					//换热量单位
					"unit_heat_load": [],
					//温度单位
					"unit_temperature": [],
					//压力单位
					"unit_pressure": []
				}
			}
		},
		created() {
			let arr = [
				"unit_length", "unit_velocity", "unit_mass_flow", "unit_volume_flow", "unit_pressure",
				"unit_temperature", "unit_heat_load", "unit_weight",
			];
			// 获取字典
			getDictListBatch(...arr).then(res => {
				Object.assign(this.pickerRanges, res)
			})

			this.$req.get("/client/im/user/unit/get", {

			}).then(async res => {
				this.form = res;

			})


		},
		methods: {
			onSubmit() {
				
				updateUserUnit(this.form).then(res => {
					this.$message.success('提交成功')
					
					setTimeout(()=>{
						this.$router.push({
							path: "/"
						}, () => {
							location.reload()
						});
										  
					},800)
					
				  
				})
			},
		}
	}
</script>

<style>
	.submit{
		width:400px;
		margin: auto;
	
		display: flex;
		justify-content: center;
	}
</style>
